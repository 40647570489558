<template>
  <div class="backgroundLoin">
    <el-card class="box-card">
      <el-form ref="formLion" :rules="rules" :model="formLion">
        <el-form-item label="注册方式" v-if="0">
          <el-radio-group v-model="formLion.registerType">
            <el-radio :disabled="disabled" :label="1">账号</el-radio>
            <el-radio :disabled="disabled" :label="2">手机号</el-radio>
            <el-radio :disabled="disabled" :label="3">邮箱</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="account" style="margin-top: 20px">
          <el-input
            prefix-icon="el-icon-user-solid"
            v-model="formLion.account"
            :placeholder="accountText"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="formLion.password"
            placeholder="密码"
            show-password
            prefix-icon="el-icon-lock"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 16px">
          <el-button
            v-throttle
            type="primary"
            :loading="loadingBtn"
            @click="onRegister('formLion')"
            >注册</el-button
          >
        </el-form-item>
      </el-form>
      <div class="clearfix">
        <router-link to="./manageLogin">
          <el-link type="primary" class="rf">去登录</el-link>
        </router-link>
      </div>
    </el-card>
  </div>
</template>

<script>
import { register } from '@/api/auth.js'
export default {
  name: 'register',
  data() {
    return {
      formLion: {
        account: '',
        // 登录类型,1为账号登录，2为手机号登录，3为邮箱登录
        registerType: 1,
        password: '',
        userType: 0,
      },
      accountText: '账号',
      loadingBtn: false,
      disabled: false,
      // 验证
      rules: {
        account: [{ required: true, message: '账号不能为空', trigger: 'blur' }],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    'formLion.registerType': function (newVal, oldVal) {
      // this.formLion.userType = newVal;
      switch (newVal) {
        case 2:
          this.accountText = '手机号'
          break
        case 3:
          this.accountText = '邮箱'
          break
        default:
          this.accountText = '账号'
      }
    },
  },
  // 在组件的 created 生命周期钩子中添加监听回车事件
  created() {
    document.addEventListener('keydown', this.handleEnterKey)
  },
  // 在组件销毁时移除监听回车事件，避免内存泄漏
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleEnterKey)
  },
  methods: {
    // 处理回车事件的方法
    handleEnterKey(event) {
      if (event.keyCode === 13) {
        this.onLogin('formLion')
      }
    },
    onRegister(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.disabled = this.loadingBtn = true
          register(this.formLion)
            .then((res) => {
              this.disabled = this.loadingBtn = false
              console.log(res)
              if (res.success) {
                this.$message.success('注册成功，正在转跳登录页面···')
                setTimeout(() => {
                  this.$router.replace('/manageLogin')
                }, 500)
              } else {
                this.$message.warning(res.msg)
              }
            })
            .catch((err) => {
              this.disabled = this.loadingBtn = false
              this.$message.error('注册失败')
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style scoped>
.backgroundLoin {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #2d3a4b;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-card {
  width: 450px;
}
.el-button--primary {
  width: 100%;
}
</style>
